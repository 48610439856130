
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">List of users</h5>
      <div class="row mb-3 gy-2 justify-content-between">
        <div class="col-md-6 col-lg-4 d-flex">
          <div class="dropdown no-arrow me-2">
            <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="bx bx-menu"></i>
            </a>
            <ul class="dropdown-menu">
              <li @click.prevent="exportUsers()">
                <button class="dropdown-item" type="button">
                  Export Excel
                </button>
              </li>
            </ul>
          </div>
          <div class="input-group w-auto">
            <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
            <select v-model="defaultRole" class="form-select flex-grow-1">
              <option value="" disabled selected>-- sort by role --</option>
              <option v-for="(role, tIndex) in roleOptions"
                      :value="role.value" :key="tIndex" > {{role.label}} </option>
            </select>
          </div>
        </div>
        <div class="col-md-5">
          <div class="input-group mb-0">
            <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
            <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <p class="mb-0">Showing {{paginationInfo}} results</p>
      </div>
      <div class="card">
        <div class="card-body p-lg-3">
          <div class="table-responsive mb-0 loading-viewport">
            <is-loading v-if="isLoading" :box="true" />
            <b-table striped hover ref="selectableTable" :items="pageData.data"
              :fields="columns" responsive="sm" align="middle">
              <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                v-slot:[`head(${column.key})`] >
                  <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                  d-flex align-items-center">
                    {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                  </div>
              </template>
              <template v-slot:head(check)>
                <div style="width:3.5rem;" class="form-check text-center">
                  <label class="form-check-label me-2" for="tableSRadioHead">
                    <input @change="selectAllRows($event)" type="checkbox"
                      class="form-check-input" id="tableSRadioHead" />
                  </label>
                </div>
              </template>
              <template v-slot:cell(check)="data">
                <div style="width:3.5rem;" class="form-check text-center me-auto">
                  <input v-model="selectedItems" type="checkbox" :value="data.item.id" class="form-check-input me-2"
                    :id="`tableSRadio${data.item.id}`" @click="handleCheckboxClick($event, data.item)" />
                  <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                </div>
              </template>
              <template v-slot:cell(image)="data">
                <div class="cursor-pointer">
                  <img @click="showProfileImg(data.item.image)" class="avatar rounded-circle" :src="absoluteUrl(data.item.image)" />
                </div>
              </template>
              <template v-slot:cell(name)="data">
                <div style="min-width:10rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm text-capitalize">{{ data.item.first_name }} {{ data.item.last_name}}</span>
                </div>
              </template>
              <template v-slot:cell(mobile)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.mobile }}</span>
                </div>
              </template>
              <template v-slot:cell(email)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.email }}</span>
                </div>
              </template>
              <template v-slot:cell(status)="data">
                <div @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span v-html="statusBadge(data.item.status)" class=""></span>
                </div>
              </template>
              <template v-slot:cell(is_verified)="data">
                <span class="text-capitalize badge" :class="data.item.is_verified ? 'bg-primary' : 'bg-danger'"> 
                    {{ data.item.is_verified ? '✓' :'X' }} </span>
              </template>
              <template v-slot:cell(group)="data">
                <div v-if="data.item.group" style="min-width:10rem;" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.group.name }}</span>
                </div>
              </template>
              <template v-slot:cell(residential_address)="data">
                <div style="min-width:15rem;" v-if="data.item.residential_address" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm">{{ data.item.residential_address.street_address }}</span> <br>
                  <span class="text-dark font-sm" v-if="data.item.residential_address.lga">{{ data.item.residential_address.lga.name }}</span>,
                  <span class="text-dark font-sm" v-if="data.item.residential_address.state">{{ data.item.residential_address.state.name }}</span>
                </div>
              </template>
              <template v-slot:cell(home_address)="data">
                <div style="min-width:20rem;" v-if="data.item.home_address" @click="navigateTo(data.item.id)"  class="cursor-pointer">
                  <span class="text-dark font-sm" v-if="data.item.home_address.unit">{{ data.item.home_address.unit.name }}</span> <br>
                  <span class="text-dark font-sm" v-if="data.item.home_address.ward">{{ data.item.home_address.ward.name }}</span>,
                  <span class="text-dark font-sm" v-if="data.item.home_address.lga">{{ data.item.home_address.lga.name }}</span>
                </div>
              </template>
              <template v-slot:cell(created_at)="data">
                <div @click="navigateTo(data.item.id)" style="width:6rem;" class="cursor-pointer">
                  {{$filters.date(data.item.created_at) }} 
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
      <div class="mb-4">
        <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
        :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import _ from 'lodash';

export default {
  name: "user-list",
  components:{
    IsLoading,
  },
  data() {
    return {
      isLoading: true,
      defaultRole: null,
      searchQuery: "",
      popupModalInvitation: false,
      roleOptions:[
        {value: null, label: "All", },
        {value: "admin", label: "Admin"},
        {value: "staff", label: "Staff"},
        {value: "grouphead", label: "Group Head"},
        {value: "member", label: "Member"},
      ],
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "check",
        },
        {
          key: "image",
          label: "Image",
        },
        {
          key: "name",
          label: "Name",
        },
        {
          key: "mobile",
          label: "Phone",
          sortable: true
        },
        {
          key: "email",
          label: "Email",
          sortable: true
        },
        {
          key: "status",
          label: "Status",
          sortable: true
        },
        {
          key: "is_verified",
          label: "Verified",
          sortable: true
        },
        {
          key: "group",
          label: "Group",
        },
        {
          key: "residential_address",
          label: "Residential Address",
        },
        {
          key: "home_address",
          label: "Polling Unit",
        },
        {
          key: "created_at",
          label: "Created at",
        },
      ],
      sortBy: {
        sort_column: "created_at",
        sort_direction: "asc"
      },
      selectedItems:[],
      lastCheckedIndex: null,
    }
  },
  watch: {
    "$route.query" : "refreshQuery",
    defaultRole (val) {
      this.fetchItems()
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.AppActiveUser
    },
    pageData(){
      return this.$store.state.userList.pageData
    },
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 10
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
    getSortingLabel(){
      return key => {
        let iconClass = 'text-muted ri-arrow-up-line';
        if(this.sortBy.sort_column != key) return `<span class='ms-2 ${iconClass}'></span>`;
        iconClass = this.sortBy.sort_direction == 'desc' ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
        return `<span class='ms-2 ${iconClass}'></span>`
      }
    }
  },
  methods: {
    navigateTo(id){
      if(id == this.activeUser.id){
        this.$router.push({path: '/account'});
      }else{
        this.$router.push({path:`/users/${id}`});
      }
    },
    selectAllRows(e) {
      if(e.target.checked){
        this.selectedItems = this.pageData.data.map(i => i.id );
      }else{
        this.selectedItems = []
      }
    },
    refreshQuery(){
      if(this.$route.query.role){
        this.defaultRole = this.$route.query.role
      }
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy
      }
      if(this.defaultRole){ payload.role = this.defaultRole }
      if(this.searchQuery){ payload.search = this.searchQuery }
      this.isLoading = true
      this.$store.dispatch("userList/fetchUsers", payload)
      .then(response => this.isLoading = false)
    },
    setSortOrder(column) {
      if (this.sortBy.sort_column === column.key) {
        this.sortBy.sort_direction = this.sortBy.sort_direction === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortBy.sort_column = column.key;
        this.sortBy.sort_direction = 'desc';
      }
      this.fetchItems();
    },
    exportUsers() {
      let payload = {
        url: '/users/export-excel',
        filename: 'users_',
        data: {}
      }
      if(this.defaultRole){ payload.data.role = this.defaultRole}
      this.$store.dispatch("changeLoaderValue", true)
      this.exelFileExport(payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate clients');
          }
      })
    },
    initializeAll(){
      this.popupModalInvitation = false
    },
    handleCheckboxClick(event, item) {
      const currentIndex = this.pageData.data.findIndex(i => i.id === item.id);
      if (this.lastCheckedIndex !== null && event.shiftKey) {
        let start = Math.min(this.lastCheckedIndex, currentIndex);
        let end = Math.max(this.lastCheckedIndex, currentIndex);

        for (let i = start; i <= end; i++) {
          const itemId = this.pageData.data[i].id;
          if (!this.selectedItems.includes(itemId)) {
            this.selectedItems.push(itemId);
          }
        }
      } else {
        this.lastCheckedIndex = currentIndex;
      }
    },
    initializeAll(){
      this.selectedItems = [];
      this.lastCheckedIndex = null
    }
  },
  created() {
    this.refreshQuery()
    if(!this.defaultRole){this.fetchItems()}
  }
}

</script>

